<template>
  <div>
    <!-- Masthead-->
    <header class="masthead bg-white">
      <div class="container h-100">
        <div
          class="row h-100 align-items-center justify-content-center text-center"
        >
          <div class="col-lg-10 align-self-end">
            <h1>404</h1>
          </div>
          <div class="col-lg-8 align-self-baseline">
            <p class="mb-5">Page not found</p>
            <button
              class="btn btn-blue rounded-pill px-5 my-4 text-uppercase"
              @click="goToHome()"
            >
              Back to Home
            </button>
          </div>
        </div>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  methods: {
    goToHome() {
      this.$router.push("/");
    },
  },
};
</script>
